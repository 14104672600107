<template>
  <div class="flex flex-col min-w-70">
    <OrganismsNav v-bind="_omit(navProps, 'showMegaNav')" />

    <div
      class="flex-1"
      :class="layoutsStore.getLayoutBg"
    >
      <slot />
    </div>

    <OrganismsFooter v-if="layoutsStore.footer.show" />
  </div>
</template>

<script setup>
import _omit from 'underscore/cjs/omit.js'

const layoutsStore = useLayoutsStore()
const navProps = layoutsStore.navigation

defineOptions({
  name: 'LayoutsDefault'
})

useOneTap()
</script>
